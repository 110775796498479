import { Button, Input, Label, Loader } from "@ezzdin_atef/react-components";
import Link from "@tiptap/extension-link";
import { EditorContent, extensions, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import axios from "axios";
import { useFormik } from "formik";
import React, { ChangeEventHandler, useEffect } from "react";
import { FaExclamationCircle, FaTimesCircle } from "react-icons/fa";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { MenuBar } from "../../components/editor/MenuBar";

interface Props {
  name: string;
}

export const EditJob: React.FC = () => {
  const { id } = useParams();
  const { mutate, isError, error, isLoading, data } = useMutation(
    async (data: any) => {
      return await axios.patch(`/jobs/${id}`, data);
    }
  );
  const { data: job, isLoading: jobLoading } = useQuery("Get Job", async () => {
    const response = await axios.get(`/jobs/${id}`);
    return response.data;
  });

  const navigate = useNavigate();
  const formik = useFormik<Props>({
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      try {
        await mutate({
          ...values,
          description: descriptionEditor?.getHTML(),
          requirements: requirementsEditor?.getHTML(),
        });
      } catch (error) {
        console.log(error);
      }
    },
  });

  const requirementsEditor = useEditor({
    extensions: [
      StarterKit,
      Link.configure({
        openOnClick: false,
      }),
    ],
    content: "",
  });

  const descriptionEditor = useEditor({
    extensions: [
      StarterKit,
      Link.configure({
        openOnClick: false,
      }),
    ],
    content: "",
  });

  useEffect(() => {
    if (job) {
      formik.setFieldValue("name", job?.name);
      descriptionEditor?.commands.setContent(job?.description);
      requirementsEditor?.commands.setContent(job?.requirements);
    }
  }, [job]);

  useEffect(() => {
    if (data && !isError) {
      navigate(`/jobs`);
    }
  }, [data]);

  if (jobLoading) {
    return (
      <div className="flex justify-center items-center pt-32">
        <Loader />
      </div>
    );
  }

  return (
    <div className="flex">
      <form
        className="flex w-8/12 flex-col gap-4 "
        onSubmit={formik.handleSubmit}
      >
        <>
          <h3 className="text-3xl font-medium py-5">Edit Job</h3>

          {error && (
            <p className="flex items-center gap-2 rounded-md bg-red-500 p-2 text-white">
              <FaExclamationCircle />
              {/* {error.message} */}
            </p>
          )}
          <div className="w-full">
            <Label htmlFor="name">Name</Label>
            <Input
              type="text"
              id="name"
              {...formik.getFieldProps("name")}
              error={formik.touched.name ? formik.errors.name : ""}
            />
          </div>

          <div className="w-full">
            <Label htmlFor="description">Description</Label>
            <div className="border my-1 rounded-lg">
              <MenuBar editor={descriptionEditor} />
              <EditorContent
                editor={descriptionEditor}
                className="focus:outline-none py-2 px-1"
              />
            </div>
          </div>

          <div className="w-full">
            <Label htmlFor="requirements">Requirements</Label>
            <div className="border my-1 rounded-lg">
              <MenuBar editor={requirementsEditor} />
              <EditorContent
                editor={requirementsEditor}
                className="focus:outline-none py-2 px-1"
              />
            </div>
          </div>

          {/* <div className="w-full">
            <Label htmlFor="description">Description</Label>
            <Textarea
              className="h-32"
              id="description"
              {...formik.getFieldProps("description")}
            />
          </div>

          <div className="w-full">
            <Label htmlFor="requirements">Requirements</Label>
            <Textarea
              className="h-32"
              id="requirements"
              {...formik.getFieldProps("requirements")}
            />
          </div> */}

          <div className="flex gap-3">
            <Button green type="submit" loading={isLoading}>
              Save
            </Button>
            <Button
              type="button"
              lightRed
              onClick={() => (window.location.href = "/jobs")}
            >
              Cancel
            </Button>
          </div>
        </>
      </form>
    </div>
  );
};
