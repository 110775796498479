import axios from "axios";
import { useQuery } from "react-query";
import { AiOutlineDollar } from "react-icons/ai";
import { FaUserTie } from "react-icons/fa";
import { MdWork } from "react-icons/md";
import { BiMessageRoundedDetail } from "react-icons/bi";
import { useEffect } from "react";
import { Loader } from "@ezzdin_atef/react-components";

export const Home = () => {
  const { data, isLoading, refetch } = useQuery("Get Statistics", async () => {
    const response = await axios.get("/statistics");
    return response.data;
  });

  useEffect(() => {
    refetch();
  }, []);

  if (isLoading)
    return (
      <div className="flex justify-center items-center pt-32">
        <Loader />
      </div>
    );

  return (
    <div>
      <div className="grid grid-cols-12 gap-8 px-4 py-10">
        <a href="/jobs" className="col-span-12 lg:col-span-6 cursor-pointer">
          <div className="flex items-center gap-4 bg-slate-200 text-PrimaryBlue px-10 py-6 rounded font-bold text-2xl">
            <div className="flex justify-center items-center bg-SecondaryBlue p-3 ml-10 rounded-full">
              <MdWork className="text-4xl text-white" />
            </div>
            <p className="text-3xl">{data?.statistics?.jobs}</p>
            <p className="font-semibold">Jobs</p>
          </div>
        </a>

        <a
          href="/applicants"
          className="col-span-12 lg:col-span-6 cursor-pointer"
        >
          <div className="flex items-center gap-4 bg-slate-200 text-PrimaryBlue px-10 py-6 rounded font-bold text-2xl">
            <div className="flex justify-center items-center bg-SecondaryBlue p-3 ml-10 rounded-full">
              <FaUserTie className="text-4xl text-white" />
            </div>
            <p className="text-3xl">{data?.statistics?.applicants}</p>
            <p className="font-semibold">Applicants</p>
          </div>
        </a>

        <a
          href="/contacts"
          className="col-span-12 lg:col-span-6 cursor-pointer"
        >
          <div className="flex items-center gap-4 bg-slate-200 text-PrimaryBlue px-10 py-6 rounded font-bold text-2xl">
            <div className="flex justify-center items-center bg-SecondaryBlue p-3 ml-10 rounded-full">
              <BiMessageRoundedDetail className="text-4xl text-white" />
            </div>
            <p className="text-3xl">{data?.statistics?.messages}</p>
            <p className="font-semibold">Messages</p>
          </div>
        </a>

        <a href="/pricing" className="col-span-12 lg:col-span-6 cursor-pointer">
          <div className="col-span-12 lg:col-span-6 flex items-center gap-4 bg-slate-200 text-PrimaryBlue px-10 py-6 rounded font-bold text-2xl">
            <div className="flex justify-center items-center bg-SecondaryBlue p-3 ml-10 rounded-full">
              <AiOutlineDollar className="text-4xl text-white" />
            </div>
            <p className="text-3xl">{data?.statistics?.pricing}</p>
            <p className="font-semibold">Pricing</p>
          </div>
        </a>

        <div className="col-span-12 lg:col-span-6 flex flex-col gap-4 bg-slate-200 text-PrimaryBlue px-10 py-6 rounded">
          <h1 className="text-2xl font-bold text-PrimaryBlue">
            Latest Applicants
          </h1>
          <div className="grid grid-cols-12 gap-4 bg-slate-50 p-1 rounded font-medium">
            <p className="col-span-2 text-center">#</p>
            <p className="col-span-5">Name</p>
            <p className="col-span-5">Phone</p>
          </div>
          {data?.latest?.latestApplicants?.map((el: any, index: number) => {
            return (
              <div
                className="grid grid-cols-12 gap-4 bg-slate-50 p-1 rounded"
                key={index}
              >
                <p className="col-span-2 text-center">{index + 1}</p>
                <p className="col-span-5">{el.name}</p>
                <p className="col-span-5">{el.phone}</p>
              </div>
            );
          })}
        </div>

        <div className="col-span-12 lg:col-span-6 flex flex-col gap-4 bg-slate-200 text-PrimaryBlue px-10 py-6 rounded">
          <h1 className="text-2xl font-bold text-PrimaryBlue">
            Latest Pricing
          </h1>
          <div className="grid grid-cols-12 gap-4 bg-slate-50 p-1 rounded font-medium">
            <p className="col-span-2 text-center">#</p>
            <p className="col-span-4">Name</p>
            <p className="col-span-4">Phone</p>
            <p className="col-span-2">Type</p>
          </div>
          {data?.latest?.latestPricing?.map((el: any, index: number) => {
            return (
              <div
                className="grid grid-cols-12 gap-4 bg-slate-50 p-1 rounded"
                key={index}
              >
                <p className="col-span-2 text-center">{index + 1}</p>
                <p className="col-span-4">{el?.companyName}</p>
                <p className="col-span-4">{el?.comapnyPhone}</p>
                <p className="col-span-2">{el?.type}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
