import { useFormik } from "formik";
import React, { useEffect } from "react";
import * as Yup from "yup";
import { useMutation } from "react-query";
import { FaExclamationCircle } from "react-icons/fa";
import { Button, Input, Label, Textarea } from "@ezzdin_atef/react-components";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const AddAbout: React.FC = () => {
  const navigate = useNavigate();
  const { mutate, isError, error, isLoading, data } = useMutation(
    async (data: any) => {
      return await axios.post("/about", data);
    }
  );
  const formik = useFormik({
    initialValues: {
      descriptionEn: "",
      descriptionAr: "",
    },
    validationSchema: Yup.object({
      descriptionEn: Yup.string().required("Required"),
      descriptionAr: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      try {
        mutate(values);
      } catch (error) {
        console.log(error);
      }
    },
  });

  useEffect(() => {
    if (!isLoading && data) {
      navigate("/about");
    }
  }, [isLoading, data]);

  return (
    <div className="flex">
      <form
        className="flex w-8/12 flex-col gap-4 "
        onSubmit={formik.handleSubmit}
      >
        <>
          <h3 className="text-3xl font-medium py-5">Add New About us</h3>

          {error && (
            <p className="flex items-center gap-2 rounded-md bg-red-500 p-2 text-white">
              <FaExclamationCircle />
              {/* {error.message} */}
            </p>
          )}

          <div className="w-full">
            <Label htmlFor="descriptionEn">English Description</Label>
            <Textarea
              id="descriptionEn"
              {...formik.getFieldProps("descriptionEn")}
            />
          </div>

          <div className="w-full">
            <Label htmlFor="descriptionAr">Arabic Description</Label>
            <Textarea
              id="descriptionAr"
              {...formik.getFieldProps("descriptionAr")}
            />
          </div>

          <div className="flex gap-3">
            <Button green type="submit" loading={isLoading}>
              Add
            </Button>
            <Button type="button" lightRed onClick={() => navigate("/about")}>
              Cancel
            </Button>
          </div>
        </>
      </form>
    </div>
  );
};
