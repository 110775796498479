import { Button, Loader, Table } from "@ezzdin_atef/react-components";
import axios from "axios";
import React, { useEffect, useMemo } from "react";
import { FaEdit, FaPlus, FaTrashAlt } from "react-icons/fa";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { ShowMessageModal } from "../../components/ShowMessageModal";

export const About: React.FC = () => {
  const { data, isLoading, refetch } = useQuery("Get About", async () => {
    const response = await axios.get("/about");
    return response.data.map((el: any, index: number) => ({
      id: index + 1,
      descriptionEn: <ShowMessageModal message={el.descriptionEn} />,
      descriptionAr: <ShowMessageModal message={el.descriptionAr} />,
      actions: (
        <>
          <Link to={`/about/edit/${el.id}`}>
            <Button lightYellow>
              <FaEdit />
            </Button>
          </Link>{" "}
          {/* <Button lightRed onClick={() => handleDelete(el.id)}>
            <FaTrashAlt />
          </Button> */}
        </>
      ),
    }));
  });
  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "id",
      },
      {
        Header: "English Description",
        accessor: "descriptionEn",
      },
      {
        Header: "Arabic Description",
        accessor: "descriptionAr",
      },
      {
        Header: "",
        accessor: "actions",
      },
    ],
    []
  );

  const handleDelete = async (id: string) => {
    Swal.fire({
      title: "? Are you sure",
      text: "! You can't reverse this deletion",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "! Yes, delete",
      cancelButtonText: "Cancel",
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        await axios.patch(`/about/delete/${id}`, {
          isDeleted: true,
        });
        refetch();
        Swal.fire({
          title: "Deleted",
          text: "The Job has been removed",
          icon: "success",
          confirmButtonText: "Ok",
        });
      }
    });
  };

  useEffect(() => {
    refetch();
  }, []);

  if (isLoading)
    return (
      <div className="flex justify-center items-center pt-32">
        <Loader />
      </div>
    );

  return (
    <div>
      <header className="my-3 flex justify-between items-center">
        <h3 className="text-2xl font-medium">About Us</h3>
        {/* <Link to="add">
          <Button className="flex items-center gap-2">
            <FaPlus />
            Add New
          </Button>
        </Link> */}
      </header>
      {!data && !isLoading && <div>About us doesn't exist</div>}
      {data && <Table columns={columns} data={data} />}
    </div>
  );
};
