import { Button, Loader, Table } from "@ezzdin_atef/react-components";
import axios from "axios";
import React, { useEffect, useMemo } from "react";
import { FaEdit, FaPlus, FaTrashAlt } from "react-icons/fa";
import { useMutation, useQuery } from "react-query";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { ShowMessageModal } from "../../components/ShowMessageModal";

export const Jobs: React.FC = () => {
  const { data, isLoading, refetch } = useQuery("Get Jobs", async () => {
    const response = await axios.get("/jobs");
    return response.data.map((el: any, index: number) => ({
      id: index + 1,
      name: el.name,
      description: <ShowMessageModal message={el?.description} />,
      requirements: <ShowMessageModal message={el?.requirements} />,
      status: (
        <select
          value={el.status}
          onChange={(e) => handelStatus(el?.id, e.target.value)}
          className={
            el?.status == "Open"
              ? "text-green-500 bg-transparent outline-none font-semibold"
              : "text-red-500 bg-transparent outline-none font-semibold"
          }
        >
          <option className="text-green-500 bg-transparent" value={"Open"}>
            Open
          </option>
          <option className="text-red-500" value={"Closed"}>
            Closed
          </option>
        </select>
      ),
      actions: (
        <>
          <Link to={`/jobs/edit/${el.id}`}>
            <Button lightYellow>
              <FaEdit />
            </Button>
          </Link>{" "}
          <Button lightRed onClick={() => handleDelete(el.id)}>
            <FaTrashAlt />
          </Button>
        </>
      ),
    }));
  });
  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Requirements",
        accessor: "requirements",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "",
        accessor: "actions",
      },
    ],
    []
  );

  const handleDelete = async (id: string) => {
    Swal.fire({
      title: "? Are you sure",
      text: "! You can't reverse this deletion",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "! Yes, delete",
      cancelButtonText: "Cancel",
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        await axios.patch(`/jobs/delete/${id}`, {
          isDeleted: true,
        });
        refetch();
        Swal.fire({
          title: "Deleted",
          text: "The Job has been removed",
          icon: "success",
          confirmButtonText: "Ok",
        });
      }
    });
  };

  const handelStatus = async (id: string, status: string) => {
    await axios.patch(`/jobs/${id}`, {
      status,
    });
    refetch();
    toast.success("Status Updated");
  };

  useEffect(() => {
    refetch();
  }, []);

  if (isLoading)
    return (
      <div className="flex justify-center items-center pt-32">
        <Loader />
      </div>
    );

  return (
    <div>
      <header className="my-3 flex justify-between items-center">
        <h3 className="text-2xl font-medium">All Jobs</h3>
        <Link to="add">
          <Button className="flex items-center gap-2">
            <FaPlus />
            Add New
          </Button>
        </Link>
      </header>
      {!data && !isLoading && <div>Jobs doesn't exist</div>}
      {data && <Table columns={columns} data={data} />}
    </div>
  );
};
