import { Button } from "@ezzdin_atef/react-components";

export const NotFound: React.FC = () => {
  return (
    <div className="flex justify-center items-center flex-col gap-8 mt-20">
      <h3 className="text-6xl">Permission Denied</h3>
      <a href="/home">
        <Button>Home</Button>
      </a>
    </div>
  );
}
