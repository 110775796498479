import { Loader, Table } from "@ezzdin_atef/react-components";
import axios from "axios";
import React, { useEffect, useMemo } from "react";
import { FaDownload } from "react-icons/fa";
import { useQuery } from "react-query";
import { toast } from "react-toastify";

export const Applicants: React.FC = () => {
  const { data, isLoading, refetch } = useQuery("Get Applicants", async () => {
    const response = await axios.get("/applicants");
    return response.data.map((el: any, index: number) => ({
      id: index + 1,
      name: el.name,
      email: el.email,
      phone: el.phone,
      state: el.state,
      city: el.city,
      job: el?.job?.name,
      status: (
        <select
          value={el.status}
          onChange={(e) => handelStatus(el?.id, e.target.value)}
          className={
            el?.status === "New"
              ? "text-blue-500 bg-transparent outline-none font-semibold"
              : el?.status === "Contacted"
              ? "text-blue-700 bg-transparent outline-none font-semibold"
              : el?.status === "Accepted"
              ? "text-green-500 bg-transparent outline-none font-semibold"
              : "text-red-500 bg-transparent outline-none font-semibold"
          }
        >
          <option className="text-blue-500 bg-transparent" value={"New"}>
            New
          </option>
          <option className="text-blue-700 bg-transparent" value={"Contacted"}>
            Contacted
          </option>
          <option className="text-green-500 bg-transparent" value={"Accepted"}>
            Accepted
          </option>
          <option className="text-red-500 bg-transparent" value={"Rejected"}>
            Rejected
          </option>
        </select>
      ),
      cv: (
        <a
          href={`https://impulses-bucket.s3.us-east-2.amazonaws.com/${el?.cv}`}
          target="blank"
        >
          <button className="px-4 py-2 bg-yellow-100 text-yellow-900  hover:bg-yellow-200 focus:outline-none rounded">
            <FaDownload />
          </button>
        </a>
      ),
    }));
  });
  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone",
        accessor: "phone",
      },
      {
        Header: "State",
        accessor: "state",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "Job Name",
        accessor: "job",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "CV",
        accessor: "cv",
      },
    ],
    []
  );

  // const handleDelete = async (id: string) => {
  //   Swal.fire({
  //     title: "? Are you sure",
  //     text: "! You can't reverse this deletion",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "! Yes, delete",
  //     cancelButtonText: "Cancel",
  //   }).then(async (result: any) => {
  //     if (result.isConfirmed) {
  //       await axios.patch(`/applicants/${id}`, {
  //         isDeleted: true,
  //       });
  //       refetch();
  //       Swal.fire({
  //         title: "Deleted",
  //         text: "The Job has been removed",
  //         icon: "success",
  //         confirmButtonText: "Ok",
  //       });
  //     }
  //   });
  // };

  const handelStatus = async (id: string, status: string) => {
    await axios.patch(`/applicants/${id}`, {
      status,
    });
    refetch();
    toast.success("Status Updated");
  };

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading)
    return (
      <div className="flex justify-center items-center pt-32">
        <Loader />
      </div>
    );

  return (
    <div>
      <header className="my-3 flex justify-between items-center">
        <h3 className="text-2xl font-medium">All Applicants</h3>
      </header>
      {!data && !isLoading && <div>Applicants doesn't exist</div>}
      {data && <Table columns={columns} data={data} />}
    </div>
  );
};
