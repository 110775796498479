import { Button } from "@ezzdin_atef/react-components";
import React from "react";
import { authState } from "../recoil";
import { useSetRecoilState } from "recoil";

export const Toolbar: React.FC = () => {
  const setToken = useSetRecoilState(authState);
  const handleLogout = () => {
    localStorage.removeItem("token");
    setToken(null);
    window.location.reload();
  };
  return (
    <div className="border-b border-slate-200 py-2 flex items-center justify-between">
      <div></div>
      <div>
        <Button lightRed className="text-xs py-2" onClick={handleLogout}>
          Logout
        </Button>
      </div>
    </div>
  );
};
