import React from "react";
import { useToggleModal } from "../hooks/useToggleModel";
import { Modal } from "./modal";

export interface showMessage {
  message: string;
}

export const ShowMessageModal: React.FC<showMessage> = ({ message }) => {
  const [openModel, toggleModel] = useToggleModal();

  return (
    <>
      <a onClick={toggleModel}>
        <p
          className="mx-1 w-32 underline text-blue-400 whitespace-nowrap line-clamp-1 overflow-hidden text-ellipsis hover:cursor-pointer"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      </a>
      <Modal title="Message" handleClose={toggleModel} isOpen={openModel}>
        <p
          className="p-8 m-4 bg-slate-100 text-gray-800 rounded-lg text-lg whitespace-pre-wrap"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      </Modal>
    </>
  );
};
