import { Button, Loader, Table } from "@ezzdin_atef/react-components";
import axios from "axios";
import React, { useEffect, useMemo, useRef } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { ShowLocationModal } from "../../components/ShowLocation";
import { AiOutlineLink } from "react-icons/ai";

export const Pricing: React.FC = () => {
  const { data, isLoading, refetch } = useQuery("Get Pricing", async () => {
    const response = await axios.get("/pricing");
    return response.data.map((el: any, index: number) => ({
      id: index + 1,
      companyName: el.companyName,
      comapnyPhone: el.comapnyPhone,
      email: el.email,
      type: el.type,
      address: el.address,
      location:
        el.lat === "" && el.lng === "" ? (
          "Mobile app"
        ) : (
          <div className="flex gap-2">
            <ShowLocationModal lat={el?.lat} lng={el?.lng} />
            <button
              className="text-yellow-800 bg-yellow-100 px-3 py-1 rounded"
              onClick={() =>
                handleCopyLink(
                  `https://www.google.com/maps/?q=${el.lat},${el.lng}`
                )
              }
            >
              <AiOutlineLink className="text-2xl font-extrabold" />
            </button>
          </div>
        ),
      status: (
        <select
          value={el.status}
          onChange={(e) => handelStatus(el?.id, e.target.value)}
          className={
            el?.status == "New"
              ? "text-blue-500 bg-transparent outline-none font-semibold"
              : "text-green-500 bg-transparent outline-none font-semibold"
          }
        >
          <option className="text-blue-500 bg-transparent" value={"New"}>
            New
          </option>
          <option className="text-green-500 bg-transparent" value={"Contacted"}>
            Contacted
          </option>
        </select>
      ),
    }));
  });

  const handleCopyLink = (link: string) => {
    navigator.clipboard.writeText(link);
    toast.success("Link copied to clipboard");
  };

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "id",
      },
      {
        Header: "Company Name",
        accessor: "companyName",
      },
      {
        Header: "Comapny Phone",
        accessor: "comapnyPhone",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Type",
        accessor: "type",
      },

      {
        Header: "Address",
        accessor: "address",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Location",
        accessor: "location",
      },
    ],
    []
  );

  const handelStatus = async (id: string, status: string) => {
    await axios.patch(`/pricing/${id}`, {
      status,
    });
    refetch();
    toast.success("Status Updated");
  };

  useEffect(() => {
    refetch();
  }, []);

  if (isLoading)
    return (
      <div className="flex justify-center items-center pt-32">
        <Loader />
      </div>
    );

  return (
    <div>
      <header className="my-3 flex justify-between items-center">
        <h3 className="text-2xl font-medium">All Pricing</h3>
      </header>
      {!data && !isLoading && <div>Pricing doesn't exist</div>}
      {data && <Table columns={columns} data={data} />}
    </div>
  );
};
