import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useMutation } from "react-query";
import { FaExclamationCircle } from "react-icons/fa";
import { Button, Input, Label, Textarea } from "@ezzdin_atef/react-components";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";
import { MenuBar } from "../../components/editor/MenuBar";

export const AddJob: React.FC = () => {
  const navigate = useNavigate();
  const { mutate, isError, error, isLoading, data } = useMutation(
    async (data: any) => {
      return await axios.post("/jobs", data);
    }
  );
  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      try {
        await mutate({
          ...values,
          description: descriptionEditor?.getHTML(),
          requirements: requirementsEditor?.getHTML(),
        });
      } catch (error) {
        console.log(error);
      }
    },
  });

  const requirementsEditor = useEditor({
    extensions: [
      StarterKit,
      Link.configure({
        openOnClick: false,
      }),
    ],
    content: "",
  });

  const descriptionEditor = useEditor({
    extensions: [
      StarterKit,
      Link.configure({
        openOnClick: false,
      }),
    ],
    content: "",
  });

  useEffect(() => {
    if (!isLoading && data) {
      navigate("/jobs");
    }
  }, [isLoading, data]);

  return (
    <div className="flex">
      <form
        className="flex w-8/12 flex-col gap-4 "
        onSubmit={formik.handleSubmit}
      >
        <>
          <h3 className="text-3xl font-medium py-5">Add New Job</h3>
          {error && (
            <p className="flex items-center gap-2 rounded-md bg-red-500 p-2 text-white">
              <FaExclamationCircle />
              {/* {error.message} */}
            </p>
          )}
          <div className="w-full">
            <Label htmlFor="name">Name</Label>
            <Input
              type="text"
              id="name"
              {...formik.getFieldProps("name")}
              error={formik.touched.name ? formik.errors.name : ""}
            />
          </div>

          <div className="w-full">
            <Label htmlFor="description">Description</Label>
            <div className="border my-1 rounded-lg">
              <MenuBar editor={descriptionEditor} />
              <EditorContent
                editor={descriptionEditor}
                className="focus:outline-none py-2 px-1"
              />
            </div>
          </div>

          <div className="w-full">
            <Label htmlFor="requirements">Requirements</Label>
            <div className="border my-1 rounded-lg">
              <MenuBar editor={requirementsEditor} />
              <EditorContent
                editor={requirementsEditor}
                className="focus:outline-none py-2 px-1"
              />
            </div>
          </div>

          {/* <div className="w-full">
            <Label htmlFor="description">Description</Label>
            <Textarea
              className="h-32"
              id="description"
              {...formik.getFieldProps("description")}
            />
          </div> */}
          {/* <div className="w-full">
            <Label htmlFor="requirements">Requirements</Label>
            <Textarea
              className="h-32"
              id="requirements"
              {...formik.getFieldProps("requirements")}
            />
          </div> */}
          <div className="flex gap-3">
            <Button green type="submit" loading={isLoading}>
              Add
            </Button>
            <Button type="button" lightRed onClick={() => navigate("/jobs")}>
              Cancel
            </Button>
          </div>
        </>
      </form>
    </div>
  );
};
