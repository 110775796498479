import {
  Button,
  CustomSelect,
  Input,
  Label,
  Loader,
  Select,
  Textarea,
} from "@ezzdin_atef/react-components";
import Link from "@tiptap/extension-link";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import axios from "axios";
import { useFormik } from "formik";
import React, { ChangeEventHandler, useEffect } from "react";
import { FaExclamationCircle, FaTimesCircle } from "react-icons/fa";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { MenuBar } from "../../components/editor/MenuBar";

export const EditAbout: React.FC = () => {
  const { id } = useParams();
  const { mutate, isError, error, isLoading, data } = useMutation(
    async (data: any) => {
      return await axios.patch(`/about/${id}`, data);
    }
  );
  const { data: about, isLoading: jobLoading } = useQuery(
    "Get About us",
    async () => {
      const response = await axios.get(`/about/${id}`);
      return response.data;
    }
  );

  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      descriptionEn: "",
      descriptionAr: "",
    },
    onSubmit: async (values) => {
      try {
        await mutate({
          descriptionEn: descriptionEnEditor?.getHTML(),
          descriptionAr: descriptionArEditor?.getHTML(),
        });
      } catch (error) {
        console.log(error);
      }
    },
  });

  const descriptionEnEditor = useEditor({
    extensions: [
      StarterKit,
      Link.configure({
        openOnClick: false,
      }),
    ],
    content: "",
  });

  const descriptionArEditor = useEditor({
    extensions: [
      StarterKit,
      Link.configure({
        openOnClick: false,
      }),
    ],
    content: "",
  });

  useEffect(() => {
    if (about) {
      descriptionEnEditor?.commands.setContent(about?.descriptionEn);
      descriptionArEditor?.commands.setContent(about?.descriptionAr);
    }
  }, [about]);

  useEffect(() => {
    if (data && !isError) {
      navigate(`/about`);
    }
  }, [data]);

  if (jobLoading) {
    return (
      <div className="flex justify-center items-center pt-32">
        <Loader />
      </div>
    );
  }

  return (
    <div className="flex">
      <form
        className="flex w-8/12 flex-col gap-4 "
        onSubmit={formik.handleSubmit}
      >
        <>
          <h3 className="text-3xl font-medium py-5">Edit About us</h3>

          {error && (
            <p className="flex items-center gap-2 rounded-md bg-red-500 p-2 text-white">
              <FaExclamationCircle />
              {/* {error.message} */}
            </p>
          )}

          <div className="w-full">
            <Label htmlFor="descriptionEn">English Description</Label>
            <div className="border my-1 rounded-lg">
              <MenuBar editor={descriptionEnEditor} />
              <EditorContent
                editor={descriptionEnEditor}
                className="focus:outline-none py-2 px-1"
              />
            </div>
          </div>

          <div className="w-full">
            <Label htmlFor="descriptionAr">Arabic Description</Label>
            <div className="border my-1 rounded-lg">
              <MenuBar editor={descriptionArEditor} />
              <EditorContent
                editor={descriptionArEditor}
                className="focus:outline-none py-2 px-1"
              />
            </div>
          </div>

          {/* <div className="w-full">
            <Label htmlFor="descriptionEn">English Description</Label>
            <Textarea
              id="descriptionEn"
              {...formik.getFieldProps("descriptionEn")}
            />
          </div>

          <div className="w-full">
            <Label htmlFor="descriptionAr">Arabic Description</Label>
            <Textarea
              id="descriptionAr"
              {...formik.getFieldProps("descriptionAr")}
            />
          </div> */}

          <div className="flex gap-3">
            <Button green type="submit" loading={isLoading}>
              Save
            </Button>
            <Button type="button" lightRed onClick={() => navigate("/about")}>
              Cancel
            </Button>
          </div>
        </>
      </form>
    </div>
  );
};
