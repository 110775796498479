import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "react-query";
import { Button, Input, Label } from "@ezzdin_atef/react-components";
import { FaExclamationCircle } from "react-icons/fa";
import axios from "axios";
import { authState } from "../recoil";
import { useSetRecoilState } from "recoil";

export const Login: React.FC = () => {
  const setToken = useSetRecoilState(authState);

  const { mutate, isError, error, isLoading, data } = useMutation(
    async (data: any) => {
      return await axios.post("/user/login", data);
    }
  );

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Required"),
      password: Yup.string()
        .min(6, "Should be 8 Numbers or characters")
        .required("Required"),
    }),
    onSubmit: async (values) => {
      mutate(values);
      console.log(data?.data.token);
      try {
      } catch (error) {
        console.log(error);
      }
    },
  });

  useEffect(() => {
    if (data) {
      setToken(data.data.token);
      localStorage.setItem("token", data.data.token);
      window.location.href = "/home";
    }
  }, [data]);

  return (
    <div className="flex justify-center pt-52">
      <form
        className="flex w-96 flex-col gap-4 rounded-lg border border-slate-300 p-8 shadow-sm"
        onSubmit={formik.handleSubmit}
      >
        <>
          <h3 className="text-center text-3xl font-medium">Login</h3>

          {isError && (
            <p className="flex items-center gap-2 rounded-md bg-red-500 p-2 text-white">
              <FaExclamationCircle />
              Invalid Email or Password
            </p>
          )}
          <div className="w-full">
            <Label htmlFor="email">Email</Label>
            <Input
              type="email"
              id="email"
              {...formik.getFieldProps("email")}
              error={formik.touched.email ? formik.errors.email : ""}
            />
          </div>
          <div className="w-full">
            <Label htmlFor="password">Password</Label>
            <Input
              type="password"
              id="password"
              {...formik.getFieldProps("password")}
              error={formik.touched.password ? formik.errors.password : ""}
            />
          </div>
          <Button type="submit" loading={isLoading}>
            Login
          </Button>
        </>
      </form>
    </div>
  );
};
