import { Loader, Sidebar } from "@ezzdin_atef/react-components";
import React, { useEffect, useState } from "react";
import { Navigate, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import { Toolbar } from "./components/Toolbar";
import { Login } from "./pages/Login";
import { authState } from "./recoil";
import { useRecoilValue } from "recoil";
import { decodeToken } from "react-jwt";
import { Home } from "./pages/Home";
import { Users } from "./pages/users/Index";
import { AddUser } from "./pages/users/Add";
import { Jobs } from "./pages/jobs/Index";
import { NotFound } from "./pages/NotFound";
import { AddJob } from "./pages/jobs/Add";
import { EditJob } from "./pages/jobs/Edit";
import { Applicants } from "./pages/applicants/Index";
import { Contact } from "./pages/contact/Index";
import { About } from "./pages/about/Index";
import { AddAbout } from "./pages/about/Add";
import { EditAbout } from "./pages/about/Edit";
import { Pricing } from "./pages/pricing/Index";
import axios from "axios";
import { useQuery } from "react-query";

export const App: React.FC = () => {
  const authToken = useRecoilValue(authState);

  const RedirectToApp: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
      (async () => {
        const response = await axios.post("/user/isVerify");
        if (response.data.verify) navigate("/home");
      })();
    }, []);
    return <Outlet />;
  };

  const VerifyToken = () => {
    const navigate = useNavigate();
    useEffect(() => {
      (async () => {
        const response = await axios.post("/user/isVerify");
        if (!response.data.verify) navigate("/login");
      })();
    }, []);
    return <Outlet />;
  };

  return (
    <div>
      <Routes>
        <Route path="/" element={<RedirectToApp />}>
          <Route index element={<Login />} />
          <Route path="/login" element={<Login />} />
        </Route>
        <Route path="/" element={<VerifyToken />}>
          <Route path="home" element={<Layout />}>
            <Route index element={<Home />} />
          </Route>
          <Route path="users" element={<Layout />}>
            <Route index element={<Users />} />
            <Route path="add" element={<AddUser />} />
            {/* <Route path="edit/:id" element={<div>Edit User</div>} /> */}
          </Route>
          <Route path="jobs" element={<Layout />}>
            <Route index element={<Jobs />} />
            <Route path="add" element={<AddJob />} />
            <Route path="edit/:id" element={<EditJob />} />
          </Route>
          <Route path="applicants" element={<Layout />}>
            <Route index element={<Applicants />} />
          </Route>
          <Route path="contacts" element={<Layout />}>
            <Route index element={<Contact />} />
          </Route>
          <Route path="pricing" element={<Layout />}>
            <Route index element={<Pricing />} />
          </Route>
          <Route path="about" element={<Layout />}>
            <Route index element={<About />} />
            <Route path="add" element={<AddAbout />} />
            <Route path="edit/:id" element={<EditAbout />} />
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

const Layout: React.FC = () => {
  const authToken = useRecoilValue(authState);
  if (!authToken) return <Navigate to="/login" />;

  return (
    <div className="flex" dir="ltr">
      <Sidebar
        logo="/logo.png"
        list={[
          { link: "/home", name: "Home" },
          { link: "/users", name: "Users" },
          { link: "/jobs", name: "Jobs" },
          { link: "/applicants", name: "Applicants" },
          { link: "/contacts", name: "Contacts" },
          { link: "/pricing", name: "Pricing" },
          { link: "/about", name: "about" },
        ]}
      />

      <div className="flex-1 px-5">
        <Toolbar />
        <Outlet />
      </div>
    </div>
  );
};
