import { useFormik } from "formik";
import React, { useEffect } from "react";
import * as Yup from "yup";
import { useMutation } from "react-query";
import { FaExclamationCircle } from "react-icons/fa";
import { Button, Input, Label } from "@ezzdin_atef/react-components";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const AddUser: React.FC = () => {
  const { mutate, error, isLoading, data } = useMutation(async (data: any) => {
    return await axios.post("/user", data);
  });
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      email: Yup.string().required("Required"),
      phone: Yup.string().required("Required"),
      password: Yup.string().min(8, "Should not be less than 8 characters").required("Required"),
      confirmPassword: Yup.string().min(8, "Should not be less than 8 characters").required("Required"),
      
    }),
    onSubmit: async (values) => {
      try {
        mutate(values);
      } catch (error) {
        console.log(error);
      }
    },
  });

  useEffect(() => {
    if (!isLoading && data) {
      navigate("/users");
    }
  }, [isLoading, data]);

  return (
    <div className="flex">
      <form className="flex w-8/12 flex-col gap-4 " onSubmit={formik.handleSubmit}>
        <>
          <h3 className="text-3xl font-medium py-5">Add New User</h3>

          {error && (
            <p className="flex items-center gap-2 rounded-md bg-red-500 p-2 text-white">
              <FaExclamationCircle />
              {/* {error.message} */}
            </p>
          )}
          <div className="w-full">
            <Label htmlFor="name">Name</Label>
            <Input
              type="text"
              id="name"
              {...formik.getFieldProps("name")}
              error={formik.touched.name ? formik.errors.name : ""}
            />
          </div>

          <div className="w-full">
            <Label htmlFor="email">Email</Label>
            <Input
              type="text"
              id="email"
              {...formik.getFieldProps("email")}
              error={formik.touched.email ? formik.errors.email : ""}
            />
          </div>

          <div className="w-full">
            <Label htmlFor="phone">Phone</Label>
            <Input
              type="text"
              id="phone"
              {...formik.getFieldProps("phone")}
              error={formik.touched.phone ? formik.errors.phone : ""}
            />
          </div>

          <div className="w-full">
            <Label htmlFor="password">Password</Label>
            <Input
              type="password"
              id="password"
              {...formik.getFieldProps("password")}
              error={formik.touched.password ? formik.errors.password : ""}
            />
          </div>

          <div className="w-full">
            <Label htmlFor="confirmPassword">Confirm Password</Label>
            <Input
              type="password"
              id="confirmPassword"
              {...formik.getFieldProps("confirmPassword")}
              error={formik.touched.confirmPassword ? formik.errors.confirmPassword : ""}
            />
          </div>

          <div className="flex gap-3">
            <Button green type="submit" loading={isLoading}>
              Add
            </Button>
            <Button type="button" lightRed onClick={() => navigate("/users")}>
              Cancel
            </Button>
          </div>
        </>
      </form>
    </div>
  );
};
