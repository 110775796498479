import React from "react";
import { useToggleModal } from "../hooks/useToggleModel";
import Map from "./Map";
import { Modal } from "./modal";
import { HiLocationMarker } from "react-icons/hi";
export interface showMessage {
  lat: string;
  lng: string;
}

export const ShowLocationModal: React.FC<showMessage> = ({ lat, lng }) => {
  const [openModel, toggleModel] = useToggleModal();

  return (
    <>
      <button
        className="text-red-500 bg-red-100 px-3 py-1 rounded"
        onClick={toggleModel}
      >
        <HiLocationMarker className="text-2xl font-extrabold" />
      </button>
      <Modal title="Location" handleClose={toggleModel} isOpen={openModel}>
        <Map lat={lat} lng={lng} />
      </Modal>
    </>
  );
};
