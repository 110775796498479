import { Button, Loader, Table } from "@ezzdin_atef/react-components";
import axios from "axios";
import React, { useEffect, useMemo } from "react";
import { FaEdit, FaPlus, FaTrashAlt } from "react-icons/fa";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

export const Users: React.FC = () => {
  const { data, isLoading, refetch } = useQuery("Get Users", async () => {
    const response = await axios.get("/user");
    return response.data.map((el: any, index: number) => ({
      id: index + 1,
      name: el.name,
      email: el.email,
      phone: el.phone,
      actions: (
        <>
          {/* <Button lightYellow>
            <FaEdit />
          </Button>{" "} */}
          <Button lightRed onClick={() => handleDelete(el.id)}>
            <FaTrashAlt />
          </Button>
        </>
      ),
    }));
  });
  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone",
        accessor: "phone",
      },
      {
        Header: "",
        accessor: "actions",
      },
    ],
    []
  );

  const handleDelete = async (id: string) => {
    Swal.fire({
      title: "? Are you sure",
      text: "! You can't reverse this deletion",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "! Yes, delete",
      cancelButtonText: "Cancel",
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        await axios.patch(`/user/${id}`, {
          isDeleted: true,
        });
        refetch();
        Swal.fire({
          title: "Deleted",
          text: "The User has been removed",
          icon: "success",
          confirmButtonText: "Ok",
        });
      }
    });
  };

  useEffect(() => {
    refetch();
  }, []);

  if (isLoading)
    return (
      <div className="flex justify-center items-center pt-32">
        <Loader />
      </div>
    );

  return (
    <div>
      <header className="my-3 flex justify-between items-center">
        <h3 className="text-2xl font-medium">All Users</h3>
        <Link to="add">
          <Button className="flex items-center gap-2">
            <FaPlus />
            Add New
          </Button>
        </Link>
      </header>
      {!data && !isLoading && <div>Users doesn't exist</div>}
      {data && <Table columns={columns} data={data} />}
    </div>
  );
};
