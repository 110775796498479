import * as React from "react";

const Map = ({ lat, lng }) => {
  // Create a reference to the HTML element we want to put the map on
  const mapRef = React.useRef(null);

  React.useEffect(() => {
    if (!mapRef.current) return;
    const H = window.H;
    const platform = new H.service.Platform({
      apikey: "aedeocLrYb3xQB9zjXaY8NHRHHUwCMMzqmoYguO5zlk",
    });
    const defaultLayers = platform.createDefaultLayers();
    const hMap = new H.Map(mapRef.current, defaultLayers.vector.normal.map, {
      center: { lat: Number(lat), lng: Number(lng) },
      zoom: 12,
      pixelRatio: window.devicePixelRatio || 1,
    });

    window.addEventListener("resize", () => hMap.getViewPort().resize());

    const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(hMap));

    const ui = H.ui.UI.createDefault(hMap, defaultLayers);

    var marker = new H.map.Marker({
      lat: Number(lat),
      lng: Number(lng),
    });
    hMap.addObject(marker);

    return () => {
      hMap.dispose();
    };
  }, [mapRef]);

  return <div className="map w-full h-[300px] rounded" ref={mapRef} />;
};

export default Map;
